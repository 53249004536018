/**
 * Created by Anthony Ho on 04/10/2017.
 */



define(['$window', 'ePopup', 'app', 'simpleSlider', 'siteObj', 'accessibilityFocusHelper'], ($window, ePopup, app, simpleSlider, siteObj, accessibilityFocusHelper) => {

  const ugcCarousel = () => {
    const component = {};

    const _config = {
      ugcCarousel: '[data-js-element="ugcCarousel_popupCarousel"]',
      ugcPopup: '[data-js-element="ugcCarousel_popup"]',
      thumbnailSlider: '[data-js-element="ugcCarousel_thumbnail"]',
      thumbnailTitle: '[data-js-element="ugcCarousel_title"]',
      thumbnailImage: '[data-js-element="ugcCarousel_thumbnailItemImage"]',
      thumbnailContainer: '[data-js-element="ugcCarousel_thumbnail_wrapper"]',
      popupImage: '[data-json-element="ugcCarousel_popupAssets"]',
      popupContent: '[data-json-element="ugcCarousel_popupContent"]',
      imageDataAttr: 'data-ugc-standard',
      imageMedDataAttr: 'data-ugc-medium',
      popupClassName: 'ugcCarousel_popupOverlay',
      popupTitle: '.ugcCarousel_popupContentTitle',
      popupHiddenTitle: '.ugcCarousel_popupHiddenTitle',
    };

    /**
     * init
     */
    const _init = (element) => {
      component.getElements(element);
      component.thumbnailHandler();
      component.nextImageHandler($window);
      component.mobileDevice = siteObj.siteIsMobile || $window.outerWidth < 992;

      return component;
    };

    /**
     * getElements
     */
    const _getElements = (el) => {
      component.ugcCarousel = el.querySelector(component.config.ugcCarousel);
      component.popups = el.querySelector(component.config.ugcPopup);
      component.thumbnailSlider = el.querySelector(component.config.thumbnailSlider);
      component.thumbnailImage = el.querySelector(component.config.thumbnailImage);
      component.thumbnailContainer = el.querySelector(component.config.thumbnailContainer);
      component.popupImages = el.querySelectorAll(component.config.popupImage);
      component.popupContent = el.querySelectorAll(component.config.popupContent);
      component.popupTitle = el.querySelector(component.config.popupTitle);

      component.popupOptions = {
        wrapper: '[data-js-element="ugcCarousel_popupCarousel"]',
        prev: '[data-js-element="ugcCarousel_popupButton-prev"]',
        next: '[data-js-element="ugcCarousel_popupButton-next"]',
        slide: '.ugcCarousel_popupCarouselSlider',
        loop: true,
        switchKey: true
      };

      component.ugcSlider = new simpleSlider(component.popups, component.popupOptions);

      component.thumbnailCarousel();
    };

    /**
     * thumbnailCarousel
     */
    const _thumbnailCarousel = () => {

      component.thumbnailOptions = {
        wrapper: '.ugcCarousel_thumbnailSlide',
        prev: '[data-js-element="ugcCarousel_thumbnail_buttonPrev"]',
        next: '[data-js-element="ugcCarousel_thumbnail_buttonNext"]',
        slide: '.ugcCarousel_thumbnailSlideItem',
        loop: true
      };

      component.ugcThumbnailCarousel = new simpleSlider(component.thumbnailSlider, component.thumbnailOptions);
    };

    /**
     * thumbnailHandler
     */
    const _thumbnailHandler = () => {
      component.thumbnailContainer.addEventListener('click', (e) => {
        const thumbnail = e.target;
        component.initThumbnail(thumbnail);
      });
    };

    /**
     *  initThumbnail
     * @param thumbnail
     */
    const _initThumbnail = (thumbnail) => {
      component.current = app.element.getAttribute('data-ugc-thumbnail', thumbnail);
      component.current = Number(component.current);
      component.initPopup(component.current);
      component.ugcSlider.total = component.popupImages.length;
    };

    /**
     * nextImageHandler
     */
    const _nextImageHandler = (el) => {
      // mobile needs both click and touchend event listener

      el.addEventListener('click', () => {
        component.slideIndex();
      });

      el.addEventListener('touchend', () => {
        component.slideIndex();
      });

      el.addEventListener('keydown', () => {
        component.slideIndex();
      });
    };

    /**
     * slideIndex
     */
    const _slideIndex = () => {
      if (component.current !== component.ugcSlider.current) {
        component.current = component.ugcSlider.current;
        component.renderNewSlide(component.current);
        component.focusSlide();
      }
    };

    /**
     * initPopup
     * @param sliderNumber
     * @returns {string}
     */
    const _initPopup = (sliderNumber) => {
      component.ePop = new ePopup(component.popups, component.config.popupClassName, true);
      component.renderNewSlide(sliderNumber);
      component.initSlider(sliderNumber, component.ugcSlider);

      if (component.mobileDevice) {
        let jsPopup = document.querySelector(`.${component.config.popupClassName} .js-popup-container`);
        return (jsPopup.style.top = '0px');
      }
      component.focusSlide();
    };

    /**
     * initSlider
     * @param sliderNumber
     */
    const _initSlider = (sliderNumber) => {
      component.ugcSlider.options.loop = true;
      component.ugcSlider.current = sliderNumber;
    };

    const _focusSlide = () => {
      component.popupTitle.innerText
        ? accessibilityFocusHelper.focus(component.popupTitle)
        : accessibilityFocusHelper.focus(component.ugcCarousel.querySelector(component.config.popupHiddenTitle));
    }

    /**
     * renderNewSlide
     * @param thumbnailNumber
     * @returns {*|string}
     */
    const _renderNewSlide = (thumbnailNumber) => {
      component.current = thumbnailNumber;
      component.standardImage = component.popupImages[thumbnailNumber];
      component.sliderImageUrl = app.element.getAttribute(component.config.imageDataAttr, component.standardImage);
      component.sliderMediumImageUrl = app.element.getAttribute(component.config.imageMedDataAttr, component.standardImage);
      component.popupAvatar = app.element.getAttribute('data-ugc-avatar', component.standardImage);
      component.popupCaption = app.element.getAttribute('data-ugc-caption', component.standardImage);
      component.popupUsername = app.element.getAttribute('data-ugc-username', component.standardImage);
      component.popupUserSuffix = component.ugcCarousel.getAttribute('data-user-post');

      let slideMarkup = `
      <div class="ugcCarousel_popupCarouselSlider">
        <div class="ugcCarousel_popupImageContainer">
          <div class="ugcCarousel_spinner"></div>
          <img class="ugcCarousel_popupImage" aria-hidden="true" alt="" role="presentation" src=${component.mobileDevice ? component.sliderMediumImageUrl : component.sliderImageUrl}>
        </div>
        <div class="ugcCarousel_popupContent">
          <div class="ugcCarousel_userContainer">
            <img class="ugcCarousel_popupUserAvatar" aria-hidden="true" alt="" role="presentation" src=${component.popupAvatar}>
            <h3 class="visually-hidden ugcCarousel_popupHiddenTitle"> ${component.popupUsername + component.popupUserSuffix} </h3>
            <div class="ugcCarousel_popupUsername" data-js-element="ugcCarousel_popupUsername" aria-hidden="true" role="presentational">
              ${component.popupUsername} <span class="visually-hidden"> ${component.popupUserSuffix} </span>
            </div>
        </div>
        <div class="ugcCarousel_popupContentText">${component.popupCaption}</div>
        </div>
      </div>`;

      component.ugcCarousel.innerHTML = slideMarkup;
    };

    component.config = _config;
    component.init = _init;
    component.getElements = _getElements;
    component.thumbnailCarousel = _thumbnailCarousel;
    component.thumbnailHandler = _thumbnailHandler;
    component.initThumbnail = _initThumbnail;
    component.nextImageHandler = _nextImageHandler;
    component.slideIndex = _slideIndex;
    component.initPopup = _initPopup;
    component.focusSlide = _focusSlide;
    component.initSlider = _initSlider;
    component.renderNewSlide = _renderNewSlide;

    return component;
  };

  return ugcCarousel;
});
